/**
 * Custom pages projects
 * @param conf
 */
export const ProjectsCustomPages = (conf) => {
    return {
        projectPersonalSite: {
            id: -1, // TODO: after add custom page
            route: conf.routes.ps.projectPersonalSite
        }
    }
};