/**
 * Apps constants sundries
 */
export const ConstantOther = {
    nickname: '@keygenqt',
    email: 'dev@keygenqt.com',
    copy: '© 2023 KeyGenQt',
    languages: {
        ru: 'ru',
        en: 'en'
    }
};